<template>
  <XeGenericErrorModal
    :value="true"
    :hide-close-button="true"
    :title="$t('PageAccountLockedTooManyAttempts.TitleModal').value"
    :figure-title="$t('PageAccountLockedTooManyAttempts.Title').value"
    :figure-content="$t('PageAccountLockedTooManyAttempts.Description').value"
    :action-text="$t('PageAccountLockedTooManyAttempts.ButtonOk').value"
    @actionClick="onActionClick"
  />
</template>

<script>
import { useI18nStore } from '@galileo/stores'
import { useRouter } from '@galileo/composables/useRouter'
import XeGenericErrorModal from '@galileo/components/XeGenericErrorModal/XeGenericErrorModal'

export default {
  name: 'AccountLockedTooManyAttempts',
  emits: [],
  components: {
    XeGenericErrorModal,
  },
  props: {},
  setup() {
    const { $t } = useI18nStore()

    const router = useRouter()

    const RETURN_ROUTE = {
      login: { name: 'Login' },
      registration: { name: 'RegisterCountry' },
      'forgot-password': { name: 'ForgotPassword' },
    }

    const onActionClick = () => {
      const { redirect } = router.currentRoute.params
      const returnRoute = RETURN_ROUTE[redirect] || RETURN_ROUTE.login
      router.push(returnRoute)
    }

    return {
      $t,
      onActionClick,
    }
  },
}
</script>

<style scoped></style>
